
































































































































































import {Component, Vue} from 'vue-property-decorator';
import {TrackService} from '@/api';
import UBlock from '@/ui-lib/block/Block.vue';
import UButton from '@/ui-lib/button/Button.vue';
import UTextField from '@/ui-lib/text-field/TextField.vue';
import UAlert from '@/ui-lib/alert/Alert.vue';

@Component<UploadAlbumView>({
  components: {
    UAlert,
    UBlock,
    UButton,
    UTextField,
  },

  metaInfo() {
    return {
      title: 'Добавление альбома',
    };
  },
})
export default class UploadAlbumView extends Vue {
  public artistName = '';
  public albumName = '';
  public albumCover: File | null = null;
  public tracks: File[] = [];

  public albumCoverPreview = '';

  public isLoading = false;
  public errorMessage = '';
  public fails: Record<string, string[]> = {};

  public async submit() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.errorMessage = '';
    this.fails = {};

    try {
      await TrackService.uploadAlbum({
        albumCover: this.albumCover,
        albumName: this.albumName,
        artistName: this.artistName,
        tracks: this.tracks
      });

      this.$notify({
        type: 'success',
        text: 'Альбом загружен',
      });

      this.$router.push({
        name: 'tracksDistribution'
      });
    } catch (e) {
      this.errorMessage = e?.message || 'Ошибка';
      this.fails = e?.validationFails || {};
    }

    this.isLoading = false;
  }

  public albumCoverChangeHandler(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      this.albumCover = file;
      this.albumCoverPreview = URL.createObjectURL(file);
    } else {
      this.albumCover = null;
      this.albumCoverPreview = '';
    }
  }

  public async tracksFileChangeHandler(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.tracks = Array.from(input.files);
    } else {
      this.tracks = [];
    }
  }
}
